<template>
    <div class="flex flex-column flex-auto">
        <div class="p-5">
            <div class="col-12">
                <!-- Cache assets -->
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid p-fluid">
                        <div class="col-12 w-full">
                            <p class="flex font-bold capitalize text-left text-lg">Cache assets</p>
                        </div>
                        <div class="col-12 w-full">
                            <div class="grid">
                                <Button type="button" label="Recache Active Assets" icon="pi pi-bolt" @click="recache" class="px-5 py-3 w-full right-0"></Button>
                            </div>
                        </div>
                        <div class="col-12 w-full">
                            <div class="grid">
                                <div class="col-10">
                                    <InputNumber v-model="cacheAssetId" placeholder="Asset ID" />
                                </div>
                                <div class="col-2">
                                    <Button type="button" label="Recache Specific Assets" icon="pi pi-bolt" @click="recacheSpecific" class="px-5 py-3 right-0"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Cache all graph data -->
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid p-fluid">
                        <div class="col-12 w-full">
                            <p class="flex font-bold capitalize text-left text-lg">Cache all graph data</p>
                        </div>
                        <div class="col-12 w-full">
                            <div class="grid">
                                <Button type="button" label="Recache Analytics" icon="pi pi-bolt" @click="recacheAnalytics" class="px-5 py-3 w-full right-0"></Button>
                            </div>
                        </div>
                        <div class="col-12 w-full">
                            <div class="grid">
                                <Button type="button" label="Delete Analytics Cache" icon="pi pi-trash" @click="deleteAnalyticsCache" class="px-5 py-3 w-full right-0"></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bundle Cyberlockers -->
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid p-fluid">
                        <div class="col-12 w-full">
                            <p class="flex font-bold capitalize text-left text-lg">Bundle Cyberlockers</p>
                        </div>
                        <div class="col-12 w-full">
                            <div class="grid">
                                <Button type="button" label="Bundle!" icon="pi pi-bolt" @click="bundle" class="px-5 py-3 w-full right-0"></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create new user -->
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid p-fluid">
                        <div class="col-12 w-full">
                            <p class="flex font-bold capitalize text-left text-lg">Create new user</p>
                        </div>
                        <div class="col-12 lg:col-6">
                            <InputText v-model="newUserName" placeholder="Name" />
                        </div>
                        <div class="col-12 lg:col-6">
                            <InputText v-model="newUserEmail" placeholder="Email" />
                        </div>
                        <div class="col-12 lg:col-6">
                            <Dropdown v-model="newUserClient" :options="clientInformation" optionLabel="Name" placeholder="Client" />
                        </div>
                        <div class="col-12 lg:col-6">
                            <MultiSelect v-model="newUserPermissions" :options="permissions" optionLabel="label" placeholder="Permissions" />
                        </div>

                        <div class="col-12 lg:col-6">
                            <h3 class="">Typical Permission Configurations</h3>
                            <li style="list-style-type: none;">
                                <b>Admin permissions:</b> Admin, Client, Reviewer, Submitter
                            </li>
                            <li style="list-style-type: none;">
                                <b>Submitter permissions:</b> Reviewer, Submitter
                            </li>
                            <li style="list-style-type: none;">
                                <b>Client permissions:</b> Client
                            </li>
                        </div>

                        <div class="col-12 lg:col-6">
                            <h3 class="">User Created</h3>
                            <li style="list-style-type: none;">
                                <b>Username:</b> {{ newUserName }}
                            </li>
                            <li style="list-style-type: none;">
                                <b>Email:</b> {{ newUserEmail }}
                            </li>
                            <li style="list-style-type: none;">
                                <b>Password:</b> {{ createdUserPassword }}
                            </li>
                            <li style="list-style-type: none;">
                                <b>Token:</b> {{ createdUserToken }}
                            </li>
                        </div>

                        <div class="col-12">
                            <Button type="button" label="Create User" icon="pi pi-user-plus" @click="createUser" class="px-5 py-3 w-full right-0"
                                :disabled="newUserName==='' || newUserClient==='' || newUserPermissions.length===0"></Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Admin",

    data: function() {
        return {
            cacheAssetId: null,

            clientInformation: [],
            permissions: [
                {label: 'Admin', value: 'admin'},
                {label: 'Developer', value: 'developer'},
                {label: 'Reviewer', value: 'reviewer'},
                {label: 'Submitter', value: 'submitter'},
                {label: 'Client', value: 'client'},
            ],
            newUserName: '',
            newUserEmail: '',
            newUserClient: '',
            newUserPermissions: [],

            createdUserPassword: 'Waiting for a user to be created...',
            createdUserToken: 'Waiting for a user to be created...',
        }
    },

    mounted() {
        this.getClients();
    },

    methods: {
        recache() {
            this.$factory.adminPage.recache().then(() => {
                this.$emitter.emit("good-toast", "Recache Successful")
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Recache Failed")
            })
        },

        recacheSpecific() {
            this.$factory.adminPage.recacheSpecific(this.cacheAssetId).then(() => {
                this.$emitter.emit("good-toast", "Recache Successful")
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Recache Failed")
            })
        },

        recacheAnalytics() {
            this.$factory.adminPage.recacheAnalytics().then(() => {
                this.$emitter.emit("good-toast", "Recache Successful")
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Recache Failed")
            })
        },

        deleteAnalyticsCache() {
            this.$factory.adminPage.deleteAnalyticsCache().then(() => {
                this.$emitter.emit("good-toast", "Recache Successful")
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Recache Failed")
            })
        },

        bundle() {
            this.$factory.adminPage.bundle().then(() => {
                this.$emitter.emit("good-toast", "Bundle Successful")
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Bundle Failed")
            })
        },

        getClients() {
            this.clientInformation = [];
            this.$factory.client.getClientsAndLogos().then(data => {
                this.clientInformation = data;
            })
        },

        createUser() {
            console.debug("Username:", this.newUserName, "Email:", this.newUserEmail, "Client:", this.newUserClient, "Permissions:", this.newUserPermissions)
            let client_id = this.newUserClient.Id;
            let permissions = this.newUserPermissions.map((permission) => permission.value);
            this.$factory.auth.createUser(this.newUserName, this.newUserEmail, client_id, permissions).then((data) => {
                this.$emitter.emit("good-toast", "User Created")
                this.createdUserPassword = data.password;
                this.createdUserToken = data.token;
            }).catch(() => {
                this.$emitter.emit("bad-toast", "User Creation Failed")
            })
        }
    },
}
</script>

<style scoped>

</style>
